import Link from 'next/link'
import { Row, Col, Button, Form, Input, message, Image } from 'antd'
import { useEffect, useState } from 'react'
import { useAuth } from '../utils/AuthContext'
import { useRouter } from 'next/router'
import { firebaseErrors } from '../utils/firebaseErrors'
import { PeopleFirst } from '../components/loader'
import { toast } from 'react-toastify'

import Script from 'next/script'
import Head from 'next/head'

// icons
import { EyeInvisibleOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

const Login = () => {
  const router = useRouter()
  const { signIn, currentUser, loading, signInWithGoogle } = useAuth()
  const [formLoading, setFormLoading] = useState(false)
  const [showLoading, setShowLoading] = useState(false)

  const onFinish = (values) => {
    setFormLoading(true)
    let { email, password } = values
    signIn(email, password)
      .then(() => {
        console.log('logged in....')
        // message.success('Logged in succesfully', { duration: 2 })
      })
      .catch((error) => {
        let { title } = firebaseErrors(error)
        // message.error({
        //   content: title,
        // })
        toast.error(title)
        setFormLoading(false)
        setShowLoading(false)
      })
  }
  useEffect(() => {
    if (currentUser) {
      setShowLoading(true)
      if (!currentUser.stripeID) {
        router.replace('/onboarding')
      } else {
        router.replace('/campaigns')
      }
    }
  }, [currentUser, loading, router])
  if (loading || showLoading) {
    return <PeopleFirst />
  }

  return (
    <>
      <Head>
        <title>People First Creator Portal</title>
        <meta name="description" content="" />
        <meta
          name="viewport"
          content="user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height, target-densitydpi=device-dpi"
        />
        <link rel="stylesheet" href="https://use.typekit.net/flj5ppz.css" />
        <link rel="icon" href="/assets/favicon.png" />
      </Head>

      <div className="login-page-bg">
        <div className="login-container">
          <Row className="login-header" align="middle">
            <Col span={12}>
              <div className="login-logo">
                <Image
                  preview={false}
                  src="/assets/peoplefirst-logo.svg"
                  alt="People First"
                />
              </div>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                className="create-account-btn"
                onClick={() =>
                  (window.location.href = process.env.NEXT_PUBLIC_WP_API)
                }
              >
                Create Account{' '}
                <FontAwesomeIcon icon={faAngleRight} className="btn-icon" />
              </Button>
            </Col>
          </Row>

          <div className="login-content">
            <div className="login-box">
              <h3>
                <span>Creator Portal Login</span>
              </h3>
              <Form
                name="normal_login"
                className="login-form pf-form-item"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  name="email"
                  label="Email Address"
                  colon={false}
                  className="pf-form-item"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Invalid Email Address',
                      validateTrigger: 'onBlur',
                    },
                  ]}
                >
                  <Input placeholder="Username" autoComplete="off" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  colon={false}
                  autoComplete="off"
                  className="pf-form-item password"
                  rules={[
                    { required: true, message: 'Please enter your password' },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-button"
                    loading={formLoading || loading}
                  >
                    Log in
                  </Button>
                  <span className="or">OR</span>
                  <Button
                    className="auth-with auth-google"
                    onClick={signInWithGoogle}
                  >
                    <Image
                      preview={false}
                      src="/assets/google-g.svg"
                      height="30px"
                      width="30px"
                    />
                    Login with Google
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <p className="signup-small">
              <Link href="/forgot-password">
                <a>Forgot Password?</a>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
export default Login
